<template>
  <v-row v-if="Lb_IsLoaded">
    <v-col
      cols="12"
    >
      <base-card>
        <v-card-title>{{$t('general.semester_marks')}}</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{$t('general.subject')}}
                  </th>
                  <th class="text-left">
                    {{$t('general.first_semester')}}
                  </th>
                  <th class="text-left">
                    {{$t('general.second_semester')}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in La_SemesterMarks"
                  :key="index"
                >
                  <td>{{ item.subjects }}</td>
                  <td>{{ item.firstsemestermark }}</td>
                  <td>{{ item.secondsemestermark }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import store from '@/store'
import { get } from '../../../worker/worker-api';
  export default {

    metaInfo() {
      return{
        // title will be injected into parent titleTemplate
        title: this.$t('general.semester_marks'),
      }
    },
    data () {
      return {
      La_SemesterMarks: [],
      Lb_IsLoaded:false,
      }
    },
    methods: {
        getInterimReportMarks (Ai_SSP_AddressRoleID,Ai_PlanningPeriodID) {
          store.dispatch('changeThemeLoadingState', true);
          this.Lb_IsLoaded =false;
          return get(`${process.env.VUE_APP_SMT_API_URL}/student/${Ai_SSP_AddressRoleID}/semester-marks/planning-period/${Ai_PlanningPeriodID}`, {})
          .then(response => {
            this.La_SemesterMarks = response.semesterMarks;
            setTimeout(() => store.dispatch('changeThemeLoadingState', false), 700)
            this.Lb_IsLoaded = true;
        })
        .catch(error => {
          this.Lb_IsLoaded = true;
          store.dispatch('changeThemeLoadingState', false);
        });
      },
      
    },
    mounted() {
      this.getInterimReportMarks(store.getters.getPlanningPeriodID,this.$smt.getAddressRoleID(this.$route.meta.role));
    }
  }
</script>
